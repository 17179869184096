<template>
  <div class="detailbox">
    <div class="centerbox">
      <div class="bankbox point" @click="gobank">
        <img src="@/assets/images/OpenBox/back-new.png" alt="" />
        back
      </div>
      <div class="title">
        {{ item.class_name }}
      </div>
      <div class="line"></div>
      <div class="confrom">
        <div class="type">
          来自
          <span class="typetext">{{ item.type_name }}</span>
        </div>
        <div class="time">{{ item.create_time }}</div>
      </div>
      <div class="textcontent">
        {{ item.message }}
      </div>
      <div class="btnbox">
        <div class="btn point" @click="deletemg">删除邮件</div>
      </div>
    </div>
  </div>
</template>

<script>
import { deletemsgApi } from "../../../network/api";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    console.log(this.item, "传入数据");
  },
  methods: {
    gobank() {
      this.$emit("bankclick", true);
    },
    async deletemg() {
      let params = {
        ids: this.item.id,
      };
      const res = await deletemsgApi(params);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
      }
      this.$emit("bankclick", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.detailbox {
  width: 100%;
  min-height: 5rem;
  padding: 0.45rem 0;
  .centerbox {
    width: 15rem;
    height: 11.8rem;
    background: url("../../../assets/images/NewUser/pc-nuserbg1.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    .bankbox {
      position: absolute;
      left: 0.5rem;
      display: flex;
      align-items: center;
      top: 0.2rem;
      color: #8a8aca;
      font-size: 0.3rem;
      img {
        width: 0.45rem;
      }
    }
    .title {
      height: 1rem;
      font-weight: 600;
      font-size: 0.32rem;
      color: #8a8aca;
      text-align: center;
      line-height: 1rem;
    }
    .line {
      width: 9rem;
      margin: 0 auto;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .confrom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.14rem 0 0.4rem 0;
      font-weight: 400;
      font-size: 0.16rem;
      color: #626288;
      .type {
        margin-right: 0.24rem;
      }
    }
    .textcontent {
      width: 10rem;
      margin: 0 auto;
      font-weight: 400;
      font-size: 0.18rem;
      color: #b0b0e7;
    }
    .btnbox {
      margin-top: 0.9rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        width: 1.48rem;
        height: 0.45rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(95deg, #2f2f5e 0%, #222242 100%);
        font-weight: 500;
        font-size: 0.18rem;
        color: #ffffff;
        border: 0.02rem solid #6565a6;
      }
    }
  }
}
</style>
